import PropTypes from "prop-types";
import React, { useState, useEffect, useRef, useCallback } from "react";
// import {useFormState, useForm} from 'react-final-form';
// import {Message, Form} from 'semantic-ui-react';

// import {FinalField} from 'indico/react/forms';
import { Translate } from "indico/react/i18n";

import "./TemaTheme.scss";

export default function TemaTheme({
  name,
  settings: { siteKey, hCaptcha },
  wtf,
}) {
  return wtf ? (
    <WTFCloudCaptcha name={name} siteKey={siteKey} hCaptcha={hCaptcha} />
  ) : (
    <FinalCloudCaptcha name={name} siteKey={siteKey} hCaptcha={hCaptcha} />
  );
}

// AbstractStatus.propTypes = {
//   name: PropTypes.string,
//   wtf: PropTypes.bool,
//   settings: PropTypes.shape({
//     siteKey: PropTypes.string.isRequired,
//     hCaptcha: PropTypes.bool.isRequired,
//   }).isRequired,
// };

// AbstractStatus.defaultProps = {
//   name: "captcha",
//   wtf: false,
// };
