import React, { useState, useEffect } from "react";
import {
  ProSidebar,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";
// import "react-pro-sidebar/dist/css/styles.css";

import SidebarToggle from "./SidebarToggle";

export default function Sidebar({ themeHeaderStyle }) {
  const [isOpen, setOpen] = useState(false);

  // dispatch event on change
  const toggleOpen = () => {
    const newState = !isOpen;
    const sidebarStateChange = new CustomEvent("sidebarStateChange", {
      detail: newState,
    });
    document.dispatchEvent(sidebarStateChange);
    // setOpen(!isOpen);
  };
  // subscribe to event
  useEffect(() => {
    document.addEventListener("sidebarStateChange", ({ detail }) => {
      setOpen(detail);
    });
  }, [setOpen]);

  return (
    <SidebarToggle
      isOpen={isOpen}
      onClick={toggleOpen}
      color={themeHeaderStyle == "below" ? "var(--bs-dark)" : "var(--bs-secondary)"}
    />
  );
}

export const SidebarComponent = ({
  logo,
  main,
  global,
  eventContact,
  eventContactTitle,
  event,
}) => {
  const [isOpen, setOpen] = useState(false);
  // dispatch event on change
  const toggleOpen = () => {
    const newState = !isOpen;
    const sidebarStateChange = new CustomEvent("sidebarStateChange", {
      detail: newState,
    });
    document.dispatchEvent(sidebarStateChange);
    // setOpen(!isOpen);
  };

  // subscribe to event
  useEffect(() => {
    document.addEventListener("sidebarStateChange", ({ detail }) => {
      setOpen(detail);
    });
  }, [setOpen]);

  return (
    <div className="top-0 position-fixed bottom-0">
      <ProSidebar breakPoint="always" toggled={isOpen} onToggle={toggleOpen}>
        <SidebarHeader>
          <div className="d-flex flex-row align-content-center py-3">
            <div className="ps-4 flex-grow-1 me-3">
              <div
                className="d-block"
                style={{ maxWidth: "170px" }}
                dangerouslySetInnerHTML={{ __html: logo }}
              />
            </div>
            <div className="d-flex align-content-center align-items-center">
              <SidebarToggle isOpen={isOpen} onClick={toggleOpen} />
            </div>
          </div>
        </SidebarHeader>
        <SidebarContent>
          <SidebarMenuComponent items={main.length > 0 ? main : global} />
          {event.length > 0 && <SidebarMenuComponent items={event} />}
        </SidebarContent>
        {eventContact.length > 0 && (
          <SidebarFooter>
            <h4 className="ms-3 mt-3 mb-0 text-light">{eventContactTitle}</h4>
            <SidebarMenuComponent items={eventContact} />
          </SidebarFooter>
        )}
        {main.length > 0 && global.length > 0 && (
          <SidebarFooter>
            <SidebarMenuComponent items={global} />
          </SidebarFooter>
        )}
      </ProSidebar>
    </div>
  );
};

export const SidebarMenuComponent = ({ items }) => {
  return (
    <Menu iconShape="round">
      {Array.isArray(items) &&
        items.map((item) => {
          if (Array.isArray(item.items) && item.items.length > 0) {
            return (
              <SubMenu
                title={item.title}
                key={item.title}
                defaultOpen={item.active}
                icon={item.icon ? <span className={item.icon} /> : null}
              >
                {/* show item if it has a url */}
                {item.url && (
                  <MenuItem active={item.selected} key={item.title}>
                    <a href={item.url}>{item.title}</a>
                  </MenuItem>
                )}

                {item.items.map((child) => (
                  <MenuItem
                    active={child.active}
                    icon
                    key={child.title}
                    href={child.url}
                  >
                    <a href={child.url}>{child.title}</a>
                  </MenuItem>
                ))}
              </SubMenu>
            );
          }
          return (
            <MenuItem
              active={item.active}
              key={item.title}
              icon={item.icon ? <span className={item.icon} /> : null}
            >
              <a href={item.url}>{item.title}</a>
            </MenuItem>
          );
        })}
    </Menu>
  );
};
