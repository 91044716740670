import React from "react";
import ReactDOM from "react-dom";

import { registerPluginComponent } from "indico/utils/plugins";

import TemaTheme from "./TemaTheme";
import Sidebar, { SidebarComponent } from "./Sidebar";

registerPluginComponent("tema_theme", "tema_theme", TemaTheme);

(function (global) {
  // setup sidebar initialization
  global.setupSidebar = function setupSidebar() {
    const toggleContainer = document.querySelector("#sidebar-toggle-container");
    if (toggleContainer) {
      var menu = {};

      // get icon
      const logoContainer = document.querySelector("#theme_site_event_logo");
      if (logoContainer && logoContainer.innerHTML) {
        menu.logo = logoContainer.innerHTML;
      }

      // get eventContact menu content
      const themeHeaderStyle = document.querySelector(
        "#theme-header-style"
      );
      if (themeHeaderStyle && themeHeaderStyle.innerHTML) {
        menu.themeHeaderStyle = themeHeaderStyle.innerHTML;
      } else {
        menu.themeHeaderStyle = "";
      }

      // get main menu content
      const mainContent = document.querySelector("#sidebar-main-menu-content");
      if (mainContent && mainContent.innerHTML) {
        try {
          menu.main = JSON.parse(mainContent.innerHTML);
        } catch (e) {
          menu.main = [];
          console.log("main content could not be loaded");
          console.log(e);
        }
      } else {
        menu.main = [];
      }

      // get global menu content
      const globalContent = document.querySelector(
        "#sidebar-global-menu-content"
      );
      if (globalContent && globalContent.innerHTML) {
        try {
          menu.global = JSON.parse(globalContent.innerHTML);
        } catch (e) {
          menu.global = [];
          console.log("global content could not be loaded");
          console.log(e);
        }
      } else {
        menu.global = [];
      }

      // get event menu content
      const eventContent = document.querySelector(
        "#sidebar-event-menu-content"
      );
      if (eventContent && eventContent.innerHTML) {
        try {
          menu.event = JSON.parse(eventContent.innerHTML);
        } catch (e) {
          menu.event = [];
          console.log("global content could not be loaded");
          console.log(e);
        }
      } else {
        menu.event = [];
      }

      // get eventContact menu content
      const eventContactContent = document.querySelector(
        "#sidebar-event-contact-menu-content"
      );
      if (eventContactContent && eventContactContent.innerHTML) {
        try {
          menu.eventContact = JSON.parse(eventContactContent.innerHTML);
        } catch (e) {
          menu.eventContact = [];
          console.log("global content could not be loaded");
          console.log(e);
        }
      } else {
        menu.eventContact = [];
      }

      // get eventContact menu title
      const eventContactTitle = document.querySelector(
        "#sidebar-event-contact-menu-title"
      );
      if (eventContactTitle && eventContactTitle.innerHTML) {
        try {
          menu.eventContactTitle = eventContactTitle.innerHTML;
        } catch (e) {
          menu.eventContactTitle = "";
        }
      } else {
        menu.eventContactTitle = "";
      }
      ReactDOM.render(<Sidebar {...menu} />, toggleContainer);
      var sidebarContainer = document.body.appendChild(
        document.createElement("DIV")
      );
      sidebarContainer.style.cssText = "z-index: 1000;";
      ReactDOM.render(<SidebarComponent {...menu} />, sidebarContainer);
    }
  };

  // initialize sidebar on load
  document.addEventListener("DOMContentLoaded", () => {
    setupSidebar();
  });
})(window);
